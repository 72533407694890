import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import Cta from '../../../components/cta'
import ColumnAlternatingButton from '../../../components/columnAlternatingButton'
import CardIcons from '../../../components/cardIcons.js'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div
                className="mt-3 richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />{' '}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className="bg-white-grey-gradient">
        <MDBAnimation reveal type="fadeIn" duration="1587ms">
          <MDBContainer>
            <h3 className="font-alt text-left font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium">
              {post.frontmatter.quality.title}
            </h3>
            <h4 class="font-w-400 text-medium">
              {post.frontmatter.quality.subtitle}
            </h4>
            {post.frontmatter.quality.feature && (
              <MDBRow className="pt-5">
                <>
                  {post.frontmatter.quality.feature.map(features => {
                    return (
                      <CardIcons
                        collg="6"
                        colmd="6"
                        title={features.title}
                        subtitle={features.subtitle}
                        imageUrl={features.image.childImageSharp.fixed}
                        imageAltText={features.alttext}
                      />
                    )
                  })}
                </>
              </MDBRow>
            )}
          </MDBContainer>
        </MDBAnimation>
      </section>

      <section className="bg-white">
        <MDBContainer>
          <ColumnAlternatingButton
            textCol={'5'}
            imageCol={'7'}
            title={post.frontmatter.partnering.title}
            subtitle={post.frontmatter.partnering.subtitle}
            description={post.frontmatter.partnering.description}
            imageUrl={post.frontmatter.partnering.image.childImageSharp.fluid}
            imageAltText={post.frontmatter.partnering.alttext}
            placement={post.frontmatter.partnering.placement}
            titleClass="font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium"
            link={post.frontmatter.partnering.link}
            linktext={post.frontmatter.partnering.linktext}
          />
        </MDBContainer>
      </section>

      <section className="bg-blue-green-gradient">
        <MDBContainer>
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        </MDBContainer>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(
      frontmatter: {
        name: { eq: "intel-data-center-solutions-premier-support-partner" }
      }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug        
        quality {
          title
          subtitle
          feature {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                fixed(width: 70, height: 70, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        partnering {
          title
          description
          placement
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`
